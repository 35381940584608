<template>
  <div class="col-inside-lg decor-default chat">
    <div class="search-users mb-4">
      <h3>My Chats</h3>
      <div class="form-group">
        <input type="text" placeholder="Search User" class="uk-input" />
      </div>
    </div>
    <div class="chat-users">
      <div class="user" v-for="(user, i) in users" :key="`user-${i}`">
        <router-link
          :to="{
            name: `${role}.messages.show`,
            params: { id: user.id },
          }"
          @click.native="user.read = 1"
        >
          <div class="avatar">
            <img :src="user.avatar" :alt="user.name" />
            <div :class="`status ${userIsOnline(user) ? 'online' : 'off'}`"></div>
          </div>
          <div :class="`name ${user.read ? '' : 'text-bold'}`">
            {{ user.name }}
          </div>
          <div :class="`mood ${user.read ? '' : 'text-bold'}`">
            {{ user.message }}
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['users', 'role'],

  methods: {
    userIsOnline(user) {
      return this.$store.getters.getOnlineUsers.filter(u => {
        return u.id == user.id
      }).length > 0
    }
  }
}
</script>

<style scoped>
.row.row-broken {
  padding-bottom: 0;
}
.col-inside-lg {
  padding: 20px;
}
.search-users input {
  line-height: 1;
  margin-bottom: 1rem;
  padding: 10px;
}
.search-users input::placeholder {
  color: #404040;
}
.chat {
  height: auto;
  overflow-y: auto;
}
.decor-default {
  background-color: #ffffff;
}
.chat-users h6 {
  font-size: 20px;
  margin: 0 0 20px;
}
.chat-users .user {
  position: relative;
  padding: 0 0 0 50px;
  display: block;
  cursor: pointer;
  margin: 0 0 20px;
}
.chat-users .user .avatar {
  top: 0;
  left: 0;
}
.chat .avatar {
  width: 40px;
  height: 40px;
  position: absolute;
}
.chat .avatar img {
  display: block;
  border-radius: 20px;
  height: 100%;
}
.chat .avatar .status.off {
  border: 1px solid #5a5a5a;
  background: #ffffff;
}
.chat .avatar .status.online {
  background: #4caf50;
}
.chat .avatar .status.busy {
  background: #ffc107;
}
.chat .avatar .status.offline {
  background: #ed4e6e;
}
.chat-users .user .status {
  bottom: 0;
  left: 28px;
}
.chat .avatar .status {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  position: absolute;
}
.chat-users .user .name {
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 0;
}
.chat-users .user .name.text-bold,
.chat-users .user .mood.text-bold {
  font-weight: bold;
}
.chat-users .user .mood {
  font: 200 14px/20px "Raleway", sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #404040;
}
</style>