<template>
  <div class="uk-container uk-margin-small-top">
    <div v-if="! loading">
      <template v-if="users.length == 0">
        <div class="uk-card uk-card-default uk-card-body">
          <h2>My Messages</h2>
          <p>There are no messages.</p>
        </div>
      </template>
      <template v-if="users.length > 0">
        <div class="uk-grid">
          <div class="uk-width-1-4@m">
            <main-component :users="users" role="trader" />
          </div>
          <div class="uk-width-3-4@m">
            <router-view />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import MainComponent from '@/components/messages/Main'

export default {
  components: {
    MainComponent
  },

  mounted() {
    this.fetch();
  },

  data() {
    return {
      users: [],
      loading: true,
    };
  },

  methods: {
    fetch() {
      this.loading = true;
      this.$axios.get("/api/v1/messages").then((response) => {
        this.users = response.data.users;
        this.loading = false;
      });
    },
  },
};
</script>


