<template>
  <index-component />
</template>

<script>
import IndexComponent from '@/components/messages/Index'

export default {
  components: {
    IndexComponent
  }
}
</script>