<template>
  <div class="card">
    <div class="card-body">
      <h2 class="card-title"></h2>
      <div class="uk-alert uk-alert-primary">Select a chat to display</div>
    </div>
  </div>
</template>

<script>
export default {
  
}
</script>